import React from "react"
import Layout from "../components/layout"

import logo from "../images/icon.png"
// TODO: make this a component 
export default () => (
  <Layout>
    <h1>Hi! I'm George Nance</h1>
    <br></br>
    <p>
      I'm a full stack web developer from sunny Phoenix, AZ 🌵.
      </p>
      <p>
      Originally I'm from a small town in Illinois where I graudated with a computer science degree and self taught myself programming.
      </p>
      <p>I helped teach students in college how to program and got heavily involved in tutoring. While doing this I had a heavy focus on game development and creating interative technology.</p>
      <p> After moving to Phoenix, I began focusing on web technology and building web applications to help serve people. 
    </p>

    <p>When I'm not hacking away at my keyboard, I am behind a camera and traveling to new places.</p>
  </Layout>
)